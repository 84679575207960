var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-conditions-wrapper" },
    _vm._l(_vm.formattedDeviceConditionsArray, function (item) {
      return _c("device-condition", {
        key: item.label,
        attrs: {
          label: item.label,
          dataCy: item.dataCy,
          showDeviceConditionError: item.showDeviceConditionError,
          showNotAvailableIcon: _vm.showNotAvailableIcon,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
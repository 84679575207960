var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      class: "dashboard " + _vm.viewport + "-viewport",
      style: _vm.cssVars,
      attrs: { flat: "" },
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline",
                  attrs: { "data-cy": "dashboardHeader" },
                },
                [_vm._v(_vm._s(_vm.$t("dashboard.title")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "black--text icon-btn card-title-btn",
                  attrs: {
                    text: "",
                    outlined: "",
                    disabled: !_vm.totalDevices,
                  },
                  on: { click: _vm.openAddWidgets },
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "append-plus", attrs: { small: "" } },
                    [_vm._v("mdi-view-dashboard")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", align: "center" } },
            [
              _c(
                "v-card-text",
                { staticClass: "devices-summary" },
                [
                  _c("router-link", { attrs: { to: "/manage-devices" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("dashboard.devices")) + " "),
                    _c(
                      "span",
                      {
                        staticClass: "devices-summary__group border-right",
                        attrs: { md: "3" },
                      },
                      [
                        _c("span", { staticClass: "devices-count" }, [
                          _vm._v(_vm._s(_vm.totalDevices)),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.$t("dashboard.total")))]),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "devices-summary__group border-right",
                        attrs: { md: "3" },
                      },
                      [
                        _c("span", { staticClass: "active devices-count" }, [
                          _vm._v(_vm._s(_vm.activeDevices)),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("dashboard.active"))),
                        ]),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "devices-summary__group",
                        attrs: { md: "3" },
                      },
                      [
                        _c("span", { staticClass: "inactive devices-count" }, [
                          _vm._v(_vm._s(_vm.inactiveDevices)),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("dashboard.inactive"))),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "grid-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.grid.loading,
              expression: "!grid.loading",
            },
          ],
          ref: "dashboardGrid",
          staticClass: "dashboard-grid",
          attrs: {
            layout: _vm.grid.layout,
            "col-num": _vm.colNum,
            "row-height": _vm.grid.rowHeight,
            margin: _vm.margin,
            "is-draggable": _vm.grid.isDraggable,
            "is-resizable": _vm.grid.isResizable,
            "vertical-compact": _vm.verticalCompact,
            "restore-on-drag": _vm.restoreOnDrag,
            "use-css-transforms": true,
            responsive: false,
            breakpoints: _vm.grid.breakpoints,
            cols: _vm.grid.cols,
          },
          on: { "layout-updated": _vm.layoutUpdated },
        },
        _vm._l(_vm.grid.layout, function (item) {
          return _c(
            "grid-item",
            {
              key: item.i,
              class: { "widget--fullscreen": item.isFullscreen },
              attrs: {
                x: item.x,
                y: item.y,
                w: item.w,
                h: item.h,
                i: item.i,
                isResizable: item.isResizable,
                maxW: item.maxW,
                maxH: item.maxH,
                "drag-allow-from": ".vue-draggable-handle",
                "drag-ignore-from":
                  ".no-drag, .widget-fullscreen .vue-draggable-handle",
              },
              on: {
                resized: _vm.setWidgetDims,
                "container-resized": _vm.setWidgetDims,
                moved: _vm.setWidgetMoved,
              },
            },
            [
              _vm.widgetsById[item.i]
                ? _c(_vm.widgetsById[item.i].widgetComponent, {
                    tag: "component",
                    class: { disabled: _vm.widgetsById[item.i].disabled },
                    attrs: {
                      widgetId: _vm.widgetsById[item.i].id,
                      widgetType: _vm.widgetsById[item.i].widgetComponent,
                      widgetMaxDevices: _vm.widgetMaxDevices,
                      deviceIds: _vm.widgetsById[item.i].deviceIds,
                      width: _vm.widgetsById[item.i].width,
                      height: _vm.widgetsById[item.i].height,
                      settings: _vm.widgetsById[item.i].settings,
                    },
                    on: {
                      fullscreenChange: function ($event) {
                        return _vm.toggleFullscreen(item.i, $event)
                      },
                      devicesChanged: function ($event) {
                        return _vm.updateWidgetDevices(item.i, $event)
                      },
                      removeWidget: function ($event) {
                        return _vm.handleRemoveWidget(item.i, $event)
                      },
                      refreshDashboard: function ($event) {
                        return _vm.refreshDashboard()
                      },
                    },
                  })
                : _vm._e(),
              !_vm.devicesLength ? _c("LoadingContainer") : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.grid.loading,
              expression: "grid.loading",
            },
          ],
          staticClass: "text-center emptyMessage",
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" },
          }),
        ],
        1
      ),
      _vm.showEmptyMessage
        ? _c(
            "div",
            {
              staticClass: "text-center empty-message",
              attrs: { "data-cy": "dashboardEmptyMessage" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("dashboard.messages.empty")) + " ")]
          )
        : _vm._e(),
      _c("add-widgets-dialog", {
        attrs: {
          open: _vm.dialogs.addWidgets,
          widgets: _vm.widgets,
          widgetMaxDevices: _vm.widgetMaxDevices,
          "data-cy": "addWidgetsDialog",
        },
        on: {
          close: _vm.closeAddWidgets,
          widgetAdded: function ($event) {
            return _vm.addNewWidget($event)
          },
        },
      }),
      _c("remove-warning-dialog", {
        attrs: { open: _vm.dialogs.removeWarning },
        on: { ok: _vm.removeWidget, cancel: _vm.clearWidgetToRemove },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
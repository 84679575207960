var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.measurements
    ? _c("div", { staticClass: "measurement-list-wrapper" }, [
        _c(
          "div",
          { class: "measurements " + _vm.customClass },
          _vm._l(_vm.measurements, function (measurement) {
            return _c("popover-measurement", {
              key: measurement.label,
              attrs: {
                reading: measurement.reading,
                label: measurement.label,
                unitsLabel: measurement.unitsLabel,
                imageSrc: measurement.imageSrc,
                showInactiveReading: measurement.showInactiveReading,
                showRealValue: true,
                customClass: measurement.customClass,
              },
            })
          }),
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
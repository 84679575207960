var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-condition", attrs: { "data-cy": _vm.dataCy } },
    [
      _vm.showNotAvailableIcon
        ? _c("img", {
            attrs: {
              src: "/img/measurements/not_available.svg",
              alt: "Not available device condition",
            },
          })
        : _c("v-icon", { attrs: { small: "", color: _vm.color } }, [
            _vm._v(" " + _vm._s(_vm.icon) + " "),
          ]),
      _c("span", [_vm._v(_vm._s(_vm.label))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }